import {defineStore} from "pinia";
import {useLocalStorage} from "@vueuse/core";
import {toast} from "vue3-toastify";
import Toastify from "@/components/Toastify.vue";

export const useAppStore = defineStore('app', {
    state: () => ({
        listView: useLocalStorage('listView', 'table'),
        changeView: useLocalStorage('changeView', 'form'),
        miscView: useLocalStorage('miscView', 'grid'),
        orderView: useLocalStorage('orderView', 'table'),
        productListView: useLocalStorage('productListView', 'table'),
        productChangeView: useLocalStorage('productChangeView', 'dashboard'),
        variationListView: useLocalStorage('variationListView', 'table'),
        variationChangeView: useLocalStorage('variationChangeView', 'dashboard'),
        unitListView: useLocalStorage('unitListView', 'table'),
        unitChangeView: useLocalStorage('unitChangeView', 'dashboard'),
        operationListView: useLocalStorage('operationListView', 'grid'),
        operationChangeView: useLocalStorage('operationChangeView', null),
        loadSideBar: false,
        orderTypes: [
            {id: "local_pickup", name: "Tienda"},
            {id: "flat_rate", name: "Peninsula"},
            {id: "shipping_baleares", name: "Baleares"},
            {id: "shipping_test", name: "Test"},
            {id: "shipping_pickup_wow", name: "WOW"},
            {id: "free_shipping", name: "Peninsula Gratis"},
            {id: "from_baleares_return_shop_shipping", name: "Baleares / Tienda"},
            {id: "from_peninsula_return_shop_shipping", name: "Peninsula / Tienda"},
            {id: "from_shop_return_baleares_shipping", name: "Tienda / Baleares"},
            {id: "from_shop_return_peninsula_shipping", name: "Tienda / Peninsula"},
        ],
        deliveryTypes: [
            {id: "delivery", name: "Entrega a domicilio"},
            {id: "pickup", name: "Recogida en tienda"},
            {id: "wow", name: "Recogida en WOW"},
            {id: "delivery_pickup", name: "Envío y recogida"},
            {id: "pickup_delivery", name: "Recogida y Envío"},
        ],
        bookingStatuses: [
            {id: "pending", name: "Pendiente"},
            {id: "blocking", name: "Bloqueo"},
            {id: "shipping", name: "Envío"},
            {id: "renting", name: "Alquiler"},
            {id: "closed", name: "Cerrado"},
            {id: "open", name: "Abierto"},
        ],
        saleStatuses: [
            {id: "pending", name: "Pendiente"},
            {id: "closed", name: "Cerrado"},
            {id: "open", name: "Abierto"},
        ],
        typeNameColors: [
            {id: "rent_resale", name: "Alquiler / Venta", color: "primary"},
            {id: "rent", name: "Alquiler", color: "success"},
            {id: "sale", name: "Venta", color: "warning"},
            {id: "off", name: "NaN", color: "dark"},
        ],
        toasts: [],
        wcUrl: import.meta.env.VITE_WC_URL,
        wcUrlForever: import.meta.env.VITE_WC_URL_FOREVER,
    }),
    actions: {
        triggerSidebarLoad() {
            this.loadSideBar = true;
        },
        resetSidebarLoad() {
            this.loadSideBar = false;
        },
        addToast(title, message, type = 'info') {
            const today = new Date();
            toast(Toastify, {
                type: type,
                contentProps: {
                    title: title,
                    message: message,
                    date: today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
                }
            });
        },
        getType(value) {
            const type = {...this.typeNameColors.find((t) => (t.id === value))};
            if (value.archived) {
                type.color = 'secondary';
            }
            return type;
        },

        buildUrl(base, path, params = {}) {
            const url = new URL(base);
            url.pathname = path;
            Object.entries(params).forEach(([key, val]) => url.searchParams.set(key, val));
            return url.toString();
        },

        getAdminUrl(channel_id) {
            if (!channel_id) return ''
            return this.buildUrl(this.wcUrl, '/wp-admin/admin.php', {
                page: 'borow',
                channel_id,
            });
        },

        getProductUrl(channel_url, forever = false) {
            if (!channel_url) return ''
            const base = forever ? this.wcUrlForever : this.wcUrl;
            return this.buildUrl(base, `/producto/${channel_url}`);
        }
    }
});
